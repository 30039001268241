import { render, staticRenderFns } from "./HWL_Pokaz.vue?vue&type=template&id=7188c2f8&scoped=true&"
import script from "./HWL_Pokaz.vue?vue&type=script&lang=js&"
export * from "./HWL_Pokaz.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7188c2f8",
  null
  
)

export default component.exports