var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{style:(_vm.textCard ? '' : 'border-left: 3px solid #5bb55f'),attrs:{"tile":_vm.textCard,"elevation":"1","min-height":"280","height":_vm.height}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"toolbar-work-place-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),(_vm.selectedCounter.id)?_c('v-btn',{staticClass:"grey lighten-4 mr-2",attrs:{"icon":!_vm.button,"depressed":_vm.button},on:{"click":_vm.deleteDialog}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1):_vm._e(),(_vm.selectedCounter.id && _vm.personal ? true : !_vm.organization.cabinet_only && _vm.selectedCounter.id)?_c('MainModal',{attrs:{"main":{ component: 'FlatCounterLegacyReading', title: 'Покази лічильника' },"button":{
                  icon: !_vm.button,
                  color: 'grey lighten-4',
                  buttonIcon: 'mdi-plus',
                  buttonName: _vm.button ? 'Подати показник' : '',
                  buttonOutlined: _vm.button,
                  buttonIconClass: _vm.button ? 'mr-2' : ''
                },"item":{
                flat_id: _vm.flat.id,
                person_hash: _vm.flat.person_hash,
                contractor_name: _vm.flat.full_address,
                flat_counter_id: _vm.selectedCounter ? _vm.selectedCounter.id : 0,
                service_id: _vm.selectedCounter ? _vm.selectedCounter.service_id : 0,
                serial_number: _vm.selectedCounter ? _vm.selectedCounter.serial_number : '',
                month: _vm.current_month,
                submission_date: _vm.current_date,
                zone: _vm.selectedCounter ? _vm.selectedCounter.zone : 0,
                prev_value_first: _vm.getPrevValue(1),
                prev_value_second: _vm.getPrevValue(2),
                prev_value_third: _vm.getPrevValue(3),
                personal: _vm.personal
        }}}):_vm._e()],1),(_vm.meter_reading_data.length)?_c('TableComponent',{attrs:{"header":_vm.meterReadingHeaders,"items":_vm.meter_reading_data,"small":true,"sm_table":'sm-flat-reading-table',"xs_table":'xs-flat-reading-table'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }